import browserUpdate from 'browser-update';

browserUpdate({
  l: 'en',
  no_permanent_hide: true,
  required: {c: -2, e: -2, f: -2, i: 999, o: -2, s: -2},
  reminder: 0,
  reminderClosed: 24,
  shift_page_down: false,
  text: {
    msgmore:
      'We only support the latest three versions of modern browsers. The application might not work properly in unsupported browsers.',
  },
});
